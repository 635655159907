import type { ReactElement } from 'react';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';
import type { UsArkonautsPageQuery } from '../../../generated/types';
import { HomepageUsTemplate } from '../../components/templates/HomepageUsTemplate';
import { TeamPageContent } from '../../modules/team/TeamPageContent';

export type Arkonaut = {
  executive?: boolean | null | undefined;
  id?: string | null | undefined;
  teamBio?: string | null | undefined;
  teamName?: string | null | undefined;
  teamPhoto?: string | null | undefined;
  teamRole?: string | null | undefined;
};

function TeamPageUs({ data: { content } }: PageProps<UsArkonautsPageQuery>): ReactElement {
  return (
    <HomepageUsTemplate seo={content?.frontmatter?.seo}>
      <TeamPageContent content={content} />
    </HomepageUsTemplate>
  );
}

export const query = graphql`
  query UsArkonautsPage {
    content: markdownRemark(frontmatter: { layout: { eq: "team" }, locale: { eq: "en" } }) {
      frontmatter {
        locale
        teamIntro
        seo {
          title
          description
          image
        }
        teamTitle
        teamStoryIntro
        teamStoryCopy1
        teamStoryCopy2
        teamExecutivesTitle
        teamArkonautsTitle
        careersIntro
        careersCopy
        careersButton
        instructions
        teamMembers {
          teamName
          teamPhoto
          teamRole
          teamBio
          executive
          id
        }
        info {
          infoTop
          infoBottom
          id
        }
        investorsTitle
        investors {
          id
          logo
          name
          href
        }
      }
    }
  }
`;

export default TeamPageUs;
